import { css } from '@emotion/react';
import theme from './theme';

// prettier-ignore
const reset = css`
html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  button {
    padding: 0;
    border: none;
    background: transparent;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    color: unset;
    text-decoration: none;
  }
  input{
    outline: none;
    caret-color: blue;
  }


  /* custom */
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  textarea,
  textarea:focus {
    font-family: inherit;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-apperarance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding: 0px;
    resize: none;
    width: 100%;
    overflow: hidden;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }

  ::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}
`;

export const GlobalStyles = css`
  ${reset}

  html,
  body {
    height: 100%;
    font-family: 'Apple SD Gothic Neo', -apple-system, BlinkMacSystemFont,
      helvetica, Arial, sans-serif;
    white-space: pre-wrap;
    background-color: ${theme.colors.gray200};
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  main {
    width: 800px;
    @media screen and (max-width: ${theme.devices.mobile}) {
      width: 100%;
    }
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }

  #portal {
    width: 800px;

    @media screen and (max-width: ${theme.devices.mobile}) {
      width: 100%;
    }
  }

  input:focus {
    outline: none;
  }

  .hide-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const GlobalStylesForStoryGame = css`
  ${GlobalStyles}

  html,
  body {
    background-color: black;
  }
`;

export default GlobalStyles;
